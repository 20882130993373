<template>
  <div>
    <v-parallax
      dark
      src="images/parallax/plax1.jpg"
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          class='text-center hidden-sm-and-down'
          md=5
          lg=5
          xl=4
        >
        <v-img
          src='images/basics/main-screen.png'
          max-height='450'
          max-width='725'
        />
        </v-col>
        <v-col
          xl=4
          lg=5
          md=5
          sm=10
        >
          <h3 class='text-xl-h4 text-lg-h6 text-md-h5 font-weight-medium mb-2'>
            Q-Scheduler
          </h3>
          <blockquote>
            QScheduler features an easy to learn and simple to use drag and drop interface. This is used to give you a birds
            eye view of your workload and schedule. Keep the visual indicators you are used to but gain the flexibility to
            meet the changing requirements of your shop in the future.
          </blockquote>
        </v-col>
      </v-row>
    </v-parallax>
    <v-container>
      <v-layout row wrap justify-center align-center mt-5 mb-5 text-center>
        <v-flex xs12 sm10 md4 lg4 xl4 :key="index" v-for="(channel, index) in channels">
          <v-layout row wrap align-center justify-center ma-1>
            <v-flex xs12 text-xs-center>
              <v-icon size="100" color='blue-grey darken-2'>{{ channel.icon }}</v-icon>
            </v-flex>
            <v-flex xs12>
              <P class='headline'>{{ channel.headline}}</p>
            </v-flex>
            <v-flex xs10>
              {{ channel.desc }}
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>

    <v-parallax
      dark
      src="images/parallax/plax7.jpg"
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          class='text-center hidden-sm-and-down'
          md=5
          lg=5
          xl=4
        >
        <v-img
          src='images/features/daily-production.png'
          max-height='450'
          max-width='725'
        />
        </v-col>
        <v-col
          xl=4
          lg=5
          md=5
          sm=10
        >
          <h3 class='text-xl-h4 text-lg-h6 text-md-h5 font-weight-medium mb-2'>
            BUILT ON LEAN PRINCIPLES
          </h3>
          <blockquote>
            QScheduler was built from the ground up around lean principles that have been catered to the Autobody industry.
            Now it is easier than ever to implement a Continuous Flow process in your shop.
          </blockquote>
        </v-col>
      </v-row>
    </v-parallax>
    <v-container>
      <v-row
        class='mt-5'
        align="center"
        justify="center"
      >
      <v-col
        xl=6
        lg=6
        md=6
        sm=10
      >
      <h3 class='text-xl-h4 text-lg-h6 text-md-h5 font-weight-medium mb-2'>CALENDAR</h3>
      <blockquote class='text-xl-h5 text-lg-subtitle-1 text-md-body-1'>
        The weekly calendar is your current production schedule. Each job is represented by a colored block. These blocks are dropped into the
        slots on the day they start production. Each job class will have a group of slots with a colored outline. These slots represent the capacity
        for that job class on a day. These slots can be adjusted in the Settings under the job class tab.
      </blockquote>
      </v-col>
        <v-col
          class='hidden-sm-and-down'
          offset-xl=1
          xl=4
          lg=4
          md=4
          sm=10
        >
        <v-img
          lazy-src="/images/basics/calendar.png"
          max-height="100%"
          max-width="100%"
          src="/images/basics/calendar.png"
        />
        </v-col>
      </v-row>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          class='hidden-sm-and-down'
          xl=4
          lg=4
          md=4
          sm=10
        >
          <v-img
            lazy-src="/images/basics/queue.png"
            max-height="422"
            max-width="351"
            src="/images/basics/queue.png"
          />
        </v-col>
        <v-col
          offset-xl=1
          xl=6
          lg=6
          md=6
          sm=10
        >
          <h3 class='text-xl-h4 text-lg-h6 text-md-h5 font-weight-medium mb-2'>REPAIR PLANNING</h3>
          <blockquote class='text-xl-h5 text-lg-subtitle-1 text-md-body-1'>
            QScheduler includes visual indicators for the current Parts and Disassembly status on a job. This allows you
            to track your progress through the repair planning process. When your job is ready, classify it and then drop it
            into the schedule where it fits.
          </blockquote>
        </v-col>
      </v-row>
      <v-row
        align="center"
        justify="center"
      >
      <v-col

        xl=6
        lg=6
        md=6
        sm=10
      >
        <h3 class='text-xl-h4 text-lg-h6 text-md-h5 font-weight-medium mb-2'>GRAPHS AND STATS</h3>
        <blockquote  class='text-xl-h5 text-lg-subtitle-1 text-md-body-1'>
          Get useful statistics on your shop flow. Show things like the number of incoming or outgoing cars per day, vehicles in progress by
          job class and vehicles entering each department. The <b>in process</b> graph shows you how many vehicles are in your shop each day.
        </blockquote>
      </v-col>
        <v-col
          class='hidden-sm-and-down'
          offset-xl=1
          xl=4
          lg=4
          md=4
        >
        <v-img
          lazy-src="/images/features/process-graph.png"
          max-height="100%"
          max-width="100%"
          src="/images/features/process-graph.png"
        />
        </v-col>
      </v-row>
      <v-row
        class='mb-5'
        align="center"
        justify="center"
      >
        <v-col
          class='hidden-sm-and-down'
          xl=4
          lg=4
          md=4
          sm=10
        >
        <v-img
          lazy-src="/images/features/daily-production.png"
          max-height="100%"
          max-width="100%"
          src="/images/features/daily-production.png"
        />
        </v-col>
        <v-col
          offset-xl=1
          xl=6
          lg=6
          md=6
          sm=10
        >
        <h3 class='text-xl-h4 text-lg-h6 text-md-h5 font-weight-medium mb-2'>DAILY PRODUCTION</h3>
        <blockquote  class='text-xl-h5 text-lg-subtitle-1 text-md-body-1'>
          The Daily Production schedule allows you to see what vehicles are hitting a department each day. In this dialog
          you can reorder the blocks how you want and can mark them with a checkmark when they are completed.
        </blockquote>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>
<script>
  export default {
    data() {
      return{
        channels: [
          {
            headline: 'Cloud Based',
            desc: 'View the production schedule and load of any shop in your network from anywhere in the world.',
            icon: 'mdi-cloud-outline'
          },
          {
            headline: 'Easy Integration',
            desc: 'Import estimates from Audatex, CCC and Mitchell or add new jobs yourself.',
            icon: 'mdi-handshake-outline '
          },
          {
            headline: 'Summit Integration',
            desc: 'Export your jobs to Q-Scheduler for scheduling and then sync your scheduled dates back into Summit.',
            icon: 'mdi-image-filter-hdr'
          },
          {
            headline: 'Graphs and Stats',
            desc: 'Get useful statistics on your shop flow and production process.',
            icon: 'mdi-chart-bar-stacked'
          },
          {
            headline: 'Daily Production Schedule',
            desc: 'The Daily Production Schedule allows you to see what repairs are hitting a department each day.',
            icon: 'mdi-calendar-clock'
          },
          {
            headline: 'Job Class Scheduling',
            desc: 'Classifying jobs by the amount of work required for the purpose of improving quality and cycle time.',
            icon: 'mdi-calendar-month'
          }
        ],
      }
    }
  }
  </script>
