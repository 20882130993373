<template>
  <div>
    <v-parallax
      dark
      src="images/parallax/plax1.jpg"
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          class='text-center hidden-sm-and-down'
          md=5
          lg=5
          xl=4
        >
        <v-img
          src='images/basics/main-screen.png'
          max-height='450'
          max-width='725'
        />
        </v-col>
        <v-col
          xl=4
          lg=5
          md=5
          sm=10
        >
          <h3 class='text-xl-h4 text-lg-h6 text-md-h5 font-weight-medium mb-2'>
            Q-Scheduler
          </h3>
          <blockquote>
            QScheduler features an easy to learn and simple to use drag and drop interface. This is used to give you a birds
            eye view of your workload and schedule. Keep the visual indicators you are used to but gain the flexibility to
            meet the changing requirements of your shop in the future.
          </blockquote>
        </v-col>
      </v-row>
    </v-parallax>
    <v-container>
      <!-- Begin Benefit Boxes -->
        <v-layout row wrap justify-center align-center mt-5 mb-5 text-center>
          <v-flex xs12 sm10 md4 lg4 xl4 :key="index" v-for="(channel, index) in channels">
            <v-layout row wrap align-center justify-center ma-1>
              <v-flex xs12 text-xs-center>
                <v-icon size="100" color='blue-grey darken-2'>{{ channel.icon }}</v-icon>
              </v-flex>
              <v-flex xs12>
                <P class='headline'>{{ channel.headline}}</p>
              </v-flex>
              <v-flex xs10>
                {{ channel.desc }}
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      <!-- End Benefit Boxes -->
    </v-container>
    <v-parallax
      src="images/parallax/plax6.jpg"
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          class=""
          xl=4
          lg=5
          md=5
          sm=10
          xs=10
        >
          <h3 class='text-xl-h4 text-lg-h6 text-md-h5 font-weight-medium mb-2'>
            NO WHITEBOARDS, MAGNETS OR VELCRO REQUIRED!
          </h3>
          <blockquote>
            QScheduler features an easy to learn and simple to use drag and drop interface. This is used to give you a birds
            eye view of your workload and schedule. Keep the visual indicators you are used to but gain the flexibility to
            meet the changing requirements of your shop in the future.
          </blockquote>
        </v-col>
        <v-col
          class='hidden-sm-and-down'
          xl=4
          lg=5
          md=5
        >
          <v-img
            src='images/whiteboard.jpg'
            max-height='450'
            max-width='725'
          />
        </v-col>
      </v-row>
    </v-parallax>
    <v-row
      class='mt-5 mb-5 text-center'
      align="center"
      justify="center"
    >
      <v-col
        class='hidden-xs-only'
        xl=4
        lg=5
        md=10
        sm=10
      >
        <iframe width="560" height="315" src="https://www.youtube.com/embed/tlXlVrSEvGg" class='hidden-xs-and-down' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </v-col>
      <v-col
        xl=4
        lg=5
        md=10
        sm=10
        xs=10
      >
        <h3 class='text-xl-h4 text-lg-h6 text-md-h5 font-weight-medium mb-2'>Maximize Production Workflow/Improve Cycle Times</h3>
        <blockquote>
          <a href="https://www.youtube.com/embed/tlXlVrSEvGg">Check out</a> this 80 second video explaining the concepts behind Q-Scheduler and Job Segmentations for lean operations and scheduling.
        </blockquote>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  export default {
    data() {
      return{
        channels: [
          {
            headline: 'BUILT ON LEAN PRINCIPLES',
            desc: 'QScheduler was built from the ground up around lean principles that have been catered to the Autobody industry.',
            icon: 'mdi-clipboard-flow-outline'
          },
          {
            headline: 'CLOUD BASED',
            desc: 'QScheduler is in the cloud! View the production schedule and load of any shop in your network from anywhere in the world.',
            icon: 'mdi-cloud-outline'
          },
          {
            headline: 'EASY INTEGRATION',
            desc: 'Import estimates from Audutex, CCC and Mitchell or add new jobs yourself.',
            icon: 'mdi-handshake-outline'
          },
        ],
      }
    }
  }
  </script>
