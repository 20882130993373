<template>
<div>
  <v-parallax
    dark
    src="/images/parallax/plax1.jpg"
  >
    <v-row
      align="center"
      justify="center"
    >

      <v-flex xs10 sm6 md4 lg3 xl2 text-center ma-5>
        <center>
          <v-img
            lazy-src="/sumo/images/sumologo.png"
            max-height="524"
            max-width="333"
            src="/sumo/images/sumologo.png"
          />
<br/>
        </center>
        <v-card>
          <v-card-title class='xs12 justify-center font-weight-medium display-1 pt-2'>
            Q-Scheduler
          </v-card-title>
          <v-card-text>
            <h3 class='display-1 font-weight-medium light-blue--text text--darken-3'>$99</h3>
            <h3 class='font-weight-medium'>Per Month<br> $499 Setup/Installation</h3>

            <v-divider></v-divider>
            <p class='pa-1'>

            </p>
            <v-btn
              tile
              color="success"
              href="#contact">Get Started</v-btn>
          </v-card-text>
        </v-card>
      </v-flex>

    </v-row>
  </v-parallax>

  <v-container>
    <v-layout row wrap justify-center align-center mt-5 mb-5 text-center>
      <v-flex xs12 sm10 md4 lg4 xl4 :key="index" v-for="(channel, index) in channels">
        <v-layout row wrap align-center justify-center ma-1>
          <v-flex xs12 text-xs-center>
            <v-icon size="100" color='blue-grey darken-2'>{{ channel.icon }}</v-icon>
          </v-flex>
          <v-flex xs12>
            <P class='headline'>{{ channel.headline}}</p>
          </v-flex>
          <v-flex xs10>
            {{ channel.desc }}
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>

</div>
</template>

<script>

  export default {
    data() {
      return {
        channels: [
          {
            headline: 'Monthly Subscription',
            desc: 'No Contracts, No Commitment. Cancel Anytime',
            icon: 'mdi-calendar-month-outline'
          },
          {
            headline: 'Unlimited Users',
            desc: 'View the production schedule and load of any shop in your network from anywhere in the world',
            icon: 'mdi-account-group'
          },
          {
            headline: 'Easy Integration',
            desc: 'Import estimates from SummitCRS, Audutex, CCC and Mitchell or add new jobs yourself.',
            icon: 'mdi-cellphone-text'
          }
        ],
      }
    },
    methods: {
      popWindow(url) {
        window.open(url)
        }
      }
  }

</script>
