<template>
  <v-app>
    <Navigation/>
    <v-main>
      <router-view></router-view>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import Navigation from './components/navigation';
import Footer from './components/footer';

export default {
  name: 'App',

  components: {
    Navigation,
    Footer,
  }
};
</script>
