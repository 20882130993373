<template>
  <div>
    <v-carousel
      cycle
    >
      <v-carousel-item
        v-for="(ads,i) in ads"
        :key="i"
        :src="ads.plax"

      >
      <v-layout mt-5 row wrap justify-center align-center>
        <v-flex sm10 md6 lg6 xl4 pa-5>
          <center>
          <v-img
            :src='ads.titleimg'
            :max-height="ads.height"
            :max-width="ads.width"
            />
        </center>
          <h3 class='font-weight-light mt-5'>
            {{ ads.text }}
          </h3>
          <center>
          <v-btn
            class='primary mt-4'
            elevation="2"
            :href='ads.url'
            target='_blank'
          >More Information</v-btn>
        </center>
        </v-flex>
        <v-flex sm8 md4 lg4 xl3 hidden-sm-and-down pa-5 >
          <center>
          <v-img
            :class="ads.css"
            :src='ads.img'
            :max-height="ads.height"
            :max-width="ads.width"
            />
          </center>
        </v-flex>
      </v-layout>
    </v-carousel-item>
  </v-carousel>
  <v-container>
          <div class="section-title text-center">
            <br/><br/>
            <h1 class='display-1 text-uppercase font-weight-medium text-xs-center'>Contact Us Today</h1>
            <h3 class='header font-weight-medium text-xs-center'>To add Q-Scheduler in your shop!</h3>
          </div>
          <v-row
              id='contact'
              class="fill-height ma-3"
              align="top"
              justify="center">
            <v-col xs=12 md=7 lg=6>

              <v-form

                submit>
                <v-flex xs12 md12>
                  <v-text-field maxlength='50'
                                label="Your Name"
                                v-model="name"
                                id='name'
                                required></v-text-field>
                </v-flex>
                <v-flex xs12 md12>
                  <v-text-field maxlength='90'
                                label="Shop Name"
                                v-model="company"
                                id='company'
                                required></v-text-field>
                </v-flex>
                <v-flex xs12 md12>
                  <v-text-field maxlength="50"
                                label="Phone Number"
                                v-model="phone"
                                id='phone'
                                required></v-text-field>
                </v-flex>
                <v-flex xs12 md12>
                  <v-text-field maxlength="90"
                                label="E-Mail"
                                v-model="email"
                                id='email'
                                required></v-text-field>
                </v-flex>
                <v-flex xs12 md12 mt-3>
                  <v-textarea label="Message"
                              v-model="bodytext"
                              counter
                              maxlength='500'
                              id='bodytext'
                              auto-grow></v-textarea>
                  <center>
                    <!-- <div class="g-recaptcha" data-sitekey="6LeavKsUAAAAALsJqLc6FvrFpoUkGSsdi7zYLDgx"></div> -->
                    <v-btn tile color="primary" @click='emailSubmit'>Submit</v-btn>
                  </center>

                </v-flex>
              </v-form>
            </v-col>

              <v-col md=5  lg=4 class='subtitle-2 hidden-sm-and-down'>
                <div class='headline'>Houston Digital</div>
                <div>
                  Houston Digital has been in the lean software development industry since 1990. Bringing innovative ways to keep track of
                  complex daily business functions. Please submit the contact information form so that one of our representatives may contact you.
                  You may also call account services at 208-947-1738 to add SuMo today.
                </div>
                  <br/>
                <p>
                  Houston Digital Corp.<br/> 943 W Overland Rd. Suite 144<br/> Meridian, ID 83642<br/> 208-947-1738<br/>
                </p>

                <a href='mailto:sales@summitcrs.com'>sales@summitcrs.com</a><br/>


            </v-col>

          </v-row>
  </v-container>
    <v-footer padless>
      <v-layout>
        <v-flex xs12>
          <v-card flat tile class="grey darken-3 white--text text-center">
            <v-card-text>
              <v-btn v-for="(footer, index) in footer"
                     :key="index"
                     :href='footer.src'
                     class="mx-3 white--text"
                     icon>
                <v-icon size="24px">{{ footer.icon }}</v-icon>
              </v-btn>
            </v-card-text>
            <v-card-text class="white--text">
              &copy; Houston Digital Corp. {{ new Date().getFullYear() }}
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-footer>
  </div>

</template>
<script>

  const axios = require('axios');
  export default {
    data: () => {
      return {
          footer: [
            {
              icon: 'mdi-facebook',
              src: 'https://www.facebook.com/Summitcrs/'
            },
            {
              icon: 'mdi-linkedin',
              src: 'https://www.linkedin.com/company/15163057/'
            },
            {
              icon: 'mdi-youtube',
              src: 'https://www.youtube.com/channel/UCbbIMPJtE_gyQ9oIzwdVN4Q'
            }
          ],
          ads:[
            {
              plax: '/images/parallax/12r.png',
              img: '/images/ads/engage.png',
              text: 'Customers today are utilizing their mobile phones more than ever to find information, shop and communicate. With an estimated 98% open rate on SMS, businesses are investing in mobile communication and marketing. SMS is direct to your customer, cost-effective and simple to utilize. Providing the channels to communicate news, information, special offers, rewards and incentives.',
              titleimg: '/images/ads/banner.png',
              url: 'http://engagesms.net',
              css: 'hidden-sm-and-down',
              height: '685',
              width: '547'
            },
            {
              plax: '/images/parallax/plax4.jpg',
              img: '/images/ads/mopho.png',
              titleimg: '/images/ads/mophoprologo.png',
              text: 'Imaging has never been easier! MoPho Pro turns any Android or iOS device into a powerful media sharing tool that helps capture, organize and share media across your network. Remove unnecessary steps and let MoPho Pro wirelessly transfer all your images to the right location with a single touch.',
              title: 'MoPho Pro',
              url: 'http://www.summitcrs.com/MoPhoPro/index.html',
              css: 'mopho hidden-sm-and-down',
              height: '325',
              width: '415'
            },
          ]
        }

      },
      methods: {
        emailSubmit(){

          let api = axios.create({
            baseURL: 'http://q-scheduler.com/php/emailengage.php',
            method: 'post',
            headers: {
              'Access-Control-Allow-Origin':'*',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Methods': 'Post',
              'X-Requested-With': 'XMLHttpRequest'

            },
            timeout: 100000
          });

          let contact = {
            name: this.name,
            company: this.company,
            phone: this.phone,
            email: this.email,
            bodytext: this.bodytext
          }

          api.post('', contact)
            .then(response => {
              console.log('success', response.data.message)
            }).catch(error => {
              console.log(error.toJSON())
            });
            alert('Message Submited, please allow for 24-48 hours for response. If you require immediate assistance please call us at 208-947-1738.');
            location.reload();
        }

        }
      }
</script>
