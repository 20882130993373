import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import Features from '../views/features.vue'
import Pricing from '../views/pricing.vue'
import Setup from '../views/setup.vue'
import QuickStart from '../views/quick-start.vue'
import TheBasics from '../views/the-basics.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/features',
    name: 'Features',
    component: Features
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing
  },
  {
    path: '/support/setup',
    name: 'Setup',
    component: Setup
  },
  {
    path: '/support/quick-start',
    name: 'QuickStart',
    component: QuickStart
  },
  {
    path: '/support/the-basics',
    name: 'TheBasics',
    component: TheBasics
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
  return { x: 0, y: 0 }
  }
})

export default router
